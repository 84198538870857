<template>
  <div class="page-404">
    <p>Sorry, we can&apos;t find the page you&apos;re looking for</p>
    <router-link to="/">Go back to Homepage</router-link>
  </div>
</template>

<style lang="scss" scoped>
.page-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
